import React, { useEffect, useRef} from "react";

export function mapToParameterString(paramMap) {
	let params = '';
	const keylist = Object.keys(paramMap);
	if (keylist.length > 0) {
		keylist.map((key) => {
			params += `&${key}=${encodeURIComponent(paramMap[key])}`;
		});
	}
	//console.log(params);
	return params;
};

export const formatStringToTimeString = time => time.toString().replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/, "$1-$2-$3 $4:$5");

export const formatTimeSecsToTimeString = (date) => {
    /* Time Secs => Date */
    date = new Date(date * 1000);
    /* Date => String */
    let arr = [date.getFullYear()];
    arr = arr.concat([
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    ].map(item => item < 10 ? '0' + item : item));
    /* String => Time String */
    return formatStringToTimeString(arr.join(''));
}

export const formatDateToString = (d, isEndDate) => {
    const today = new Date(d);
    const year = today.getFullYear(), month = today.getMonth()+1, date = today.getDate();
    return year + "" + (month < 10 ? "0"+month : month) +"" + (date < 10 ? "0"+date : date) + (isEndDate ? "235959" : "000000");
};

export function useFirstRender() {
	const firstRender = useRef(true);
	useEffect(() => {firstRender.current = false}, []);
	return firstRender.current;
};

export function alteration(url, method, opts) {
    method = method.toUpperCase();
    if(method !== 'GET') {
        if(url.indexOf('?') === -1)
            url += '?';
        else if(!url.endsWith('?'))
            url += '&';
        url += `_method=${method}`;
    }
	let requestOptions = {
		method: method === 'GET' ? 'GET' : 'POST',
		headers: { 'Content-Type': 'application/json' }
	};
	if (opts) requestOptions = {...requestOptions, ...opts};
	return upload(url, requestOptions);
};

export function upload(url, opts) {
	return fetch(url, opts)
		.then((response) => {
			if (!response.ok && response.status == 401) {
				goErrorPage(response.status);
				throw response;
			}
			return response;
		})
		.catch(e => {
			throw { message: '현재 서버와의 통신이 원활하지 않습니다.' };
		})
};

export function goErrorPage(status) {
	let current = window.location.href;
	let idx = current.indexOf('/#');
	if (idx > -1) current = current.substr(0, idx);
	window.location.href = `${current}:error?status=${status}`;
};

export const getDomainList = (contextPath, fields, enableEdit, permissions, tenant, withoutPermission=false) => {
	
	let url = `${contextPath}/api/domains?fields=${fields?fields : 'domain,domain.name,domain.type'}&order=domain.name${tenant && tenant.length > 0 ?'&tenant=' + tenant : ''}`;
	if(enableEdit === false) url += '&mode=service';
	let permission_domains = Object.keys(permissions) 
	return fetch(url).then((response) => (response.json().then(function(data) {
		if (withoutPermission) return data;
		if(permission_domains.includes('*')) return data;

		let _data = [];
		data.map(function(domains) { 
			(permission_domains.includes(getDesignDomainId(enableEdit, domains.domain)) || domains['domain.type'] === 'group') && _data.push(domains);
		});
		
		return _data;
	})));
};

export const getDomainInfo = (contextPath, domainId, fields) => {
	let url = `${contextPath}/api/domains/${domainId}?fields=${fields ?? 'domain,domain.name,domain.type'}`;
	return fetch(url).then((response) => response.json());
};

export function formatByteSize(bytes) {
	if(bytes === 0){
		return '0 Bytes';
	}
	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export function getDataType(data) {
    if ((function () { return data && (data !== this); }).call(data))
        return typeof value;
    return ({}).toString.call(data).match(/\s([a-z|A-Z]+)/)[1].toLowerCase();
};

export function sortByKey(data) {
	var ordered = getDataType(data) == 'array' ? [] : {};
	var keys = Object.keys(data).sort();
    keys.map(k => {
        try {
			var datatype = getDataType(data[k]);
			if(datatype == 'array' || datatype == 'object')
				ordered[k] = sortByKey(data[k]);
			else
				ordered[k] = data[k];	
		}
		catch(e) {
			console.log("[error]",e);
		}
    });
	return ordered;
};

export function deleteKey(object, key) {
	for(var property in object) {
		if(property == key)
			delete object[property];
		else if(object[property] !== null && typeof object[property] == 'object')
			deleteKey(object[property], key);
	}
};

export function copyJSONObject(object) {
    return JSON.parse(JSON.stringify(object));
};

export function equalsJSONObject(json1, json2) {
	var s1 = JSON.stringify(sortByKey(json1));
	var s2 = JSON.stringify(sortByKey(json2));
	return s1 == s2;
};

export function getDesignDomainId(enableEdit, domainId) {
	if(!enableEdit && domainId.indexOf('__') > -1) {
		return domainId.substring(2)
	}
	else
		return domainId;
};

export function openHelpPage(contextPath, fileName) {
    window.open(`${contextPath}/manual/user/${fileName}.html`);
};

export function isDomainAdmin(permissions, domain) {
	if(domain.startsWith("__")) {
		domain = domain.substr(2);
	}
	
	let _permissions = Object.keys(permissions).includes("*") ? permissions["*"] : permissions[domain];

	if(_permissions === undefined) 
		 return false;
	return _permissions.includes("*/*");
};

//@author hssuh 2022-10-25 URL로 페이지 접근시 권한체크
export function roleCheck( contextPath, menu_permission) {
	fetch(`${contextPath}/api/permissions:check?permission=${menu_permission}`)
		.then((response) => {
			if (!response.ok) {
				goErrorPage(response.status);
			}
	});
}

export function accessMenu(permissions, domain, menu) {	
	if(domain.startsWith("__")) {
		domain = domain.substr(2);
	}
	let _permissions = Object.keys(permissions).includes("*") ? permissions["*"] : permissions[domain];
	if(_permissions === undefined) 
		return true;
	return _permissions.includes(menu + "/*") || _permissions.includes(menu + "/r");
};

export function enableWriteMenu(isAdmin, permissions, domain, menu) {
	if(domain.startsWith("__")) {
		domain = domain.substr(2);
	}
	let enableWrite = isAdmin || isDomainAdmin(permissions, domain);
	if(!enableWrite) {
		if(permissions[domain] === undefined) 
			return false;
		for (let item of permissions[domain]){	
			let menus = item.split("/");
			if(menu == menus[0]) {
				return !(menus[1] === "r");
			}
		}	
	}
	return enableWrite;
};
