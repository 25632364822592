import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9'; // IE9
import 'react-app-polyfill/ie11'; // IE11
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import ChatAnswer from './answer';
import { transformMessages } from './common';
import Autocomplete from '../common/autocomplete';

import '../../resources/css/chat-common.css';
import '../../resources/css/chat.css';

export {
    ChatAnswer,
    BeatLoader,
    transformMessages,
    Autocomplete,
    React,
    ReactDOM
};