import React from 'react';
import InnerHTML from 'dangerously-set-html-content';

function Text({index, message}) {
	return (
        <div>
            { message && message.text && <InnerHTML html={message.text}/> }
        </div>
    );
}

export default Text;