import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import icoSuccess from '../../resources/img/alert_success.png';
import icoWarning from '../../resources/img/alert_warning.png';
import icoInfo from '../../resources/img/alert_info.png';

export default function useAlert() {
    const [open, setOpen] = useState(false);
    const [props, setProps] = useState({});
    const [title, setTitle] = useState('');
	
    const showAlert = (isOpen, type, props) => {
        setOpen(isOpen);
        if(type)
            setProps({ ...{ type: type.toLowerCase() }, ...(props === undefined ? {} : props) });
    };

    const showConfirm = (title, content) => showSimpleAlert('confirm', title, content);
    const showError = (title, content, expandcontent) => showSimpleAlert('error', title, content, expandcontent);
    const showInfo = (title, content) => showSimpleAlert('info', title, content);

    const setAlertTitle = (title) => setTitle(title);

    const showSimpleAlert = (type, title, content, expandcontent) => {
        setOpen(true);
        setProps({type, title, content, expandcontent});
    };

    const defaultOpts = {
        /* 타입 */
        type: 'confirm', // 'confirm' || 'error' || 'info' || 'custom'
        /* 타이틀 */
        title: (!props.type || props.type === 'confirm') ? '확인' : '에러',
        /* 내용 */
        content: (!props.type || props.type === 'confirm') ? '확인해주세요.' : '에러가 발생하였습니다.<br/>다시 시도해주세요.',
        /* 숨김 내용 */
        expandcontent: null,
        /* 확인 버튼 */
        confirmText: '확인',
        onConfirm: () => showAlert(false),
        /* 취소 버튼 */
        showCancel: !props.type || props.type === 'confirm',
        cancelText: '취소',
        onCancel: () => showAlert(false)
    }

    let opts = { ...defaultOpts, ...props };

    useEffect(() => setTitle(opts.title), [props]);

    return [() => {
        let Content = '', data;
        if(opts.content) {
            // opts.content is string or html
            if(typeof opts.content === 'string') {
                Content = <div dangerouslySetInnerHTML={{ __html: opts.content }} className='content'/>;
            }
            // opts.content is jsx
            else {
                [Content, data] = opts.content();
                Content = <div className='content' style={opts.width ? {width: opts.width} : {}}><Content/></div>;
            }
        }

        useEffect(()=>{
            if (!open) props.callback ? props.callback() : null; // 모달창 닫힐때 callback 함수 실행
        },[open]);
        
        return (
            <Dialog
                className='alert-dialog-root'
                open={ open }
                onClose={ opts.onCancel }
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                style={props.style || {}} 
                fullWidth={opts.type === 'custom' ? true : false}
                maxWidth={opts.type === 'custom' ? (opts.maxWidth || 'md') : 'sm'}
                >

                <div className='item-wrap'>
                    {opts.type !== 'custom' && <img src={ opts.type === 'confirm' ? icoSuccess : opts.type === 'info' ? icoInfo : icoWarning }/>}
                    <div className='title'>{ title }</div>
                </div>
                { Content }
                { opts.expandcontent && <ExpandContent content={opts.expandcontent} /> }
                <div className='btn-wrap'>
                    <button className='btn btn-brown w100' onClick={ () => opts.onConfirm(data) }>{ opts.confirmText }</button>
                    { opts.showCancel && <button className='btn btn-brown line w100 ml5' onClick={ () => opts.onCancel(data) }>{ opts.cancelText }</button> }
                </div>
            </Dialog>
        );
    }, showAlert, showConfirm, showError, showInfo, setAlertTitle];
}

const ExpandContent = ({content}) => {
    const [expanded, setExpanded] = useState(false);

    const handleClick= (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };

	return (
		<div className={`content expand-wrap ${expanded ? 'open' : ''}`}>
            <a href="#" className="link-expand" onClick={handleClick}>
                <ExpandMoreIcon className={`icon-expand ${expanded ? 'open' : ''}`} />내용 {expanded ? '닫기' : '더보기'}
            </a>

            <div className="expand-content">{content}</div>
		</div>
	);
}