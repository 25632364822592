import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ThumbUpOutlined from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlined from '@material-ui/icons/ThumbDownOutlined';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import Carousel from 'react-material-ui-carousel';
import { alteration } from '../../common/func';
import { useStoreState, useStoreDispatch } from '../../common/store';
import useBackdrop from '../../common/backdrop';

import ChatAnswer from '../answer';

const useStyles = makeStyles({
	card: {
		borderBottom: '2px solid #6bb7a6',
        padding:'10px 15px',
        '&:last-child': {
            borderBottom: 0
        }
	},
    carousel: {
		position: 'initial',
		'& >div:nth-child(2)' : {
			right: '-8px!important',
			top : 'calc(50%) !important',
			height: '0px'
		},
		'& >div:nth-child(3)' : {
			left: '-29px!important',
			top : 'calc(50%) !important',
			height: '0px'
		},	
	},
    indicatorContainerProps: {
		marginTop: '-5px', 
		paddingBottom: '10px',
        float : 'left'
	},
	indicatorIcon: {
		color: '#c3d5d5',
		'&:hover': {
			color: '#c3d5d5',
		},
		'& .MuiSvgIcon-root': {
			fontSize: '9px'
		}
	},
	activeIndicatorIcon : { 
		color: '#7bb592',
		'&:hover': {
			color: '#7bb592',
		}
	},

	navButtonsWrapperProps: {
		bottom: '0',
		top: 'unset',
		width: '31px',
		'& >:hover': {
			filter: 'none!important',
			opacity: '1 !important'
		}
	},

    thumb : {
        cursor : 'pointer',
        marginLeft: '8px',
        marginRight: '3px',
        verticalAlign : 'middle',
		'&.MuiSvgIcon-root': {
			fontSize: 'inherit'
		}
    }
});

const ControlIcon = ({direction}) => {
	return <span className={`nav_${direction}nomal`} />
};

const Cards = ({index, message, onClick, contextPath, dynamic, user, channel, isFeedback}) => {
    if(message.align == 'horizontal')
		return <HorizontalCards cards={message.cards} onClick={onClick} contextPath={contextPath} dynamic={dynamic} user={user} channel={channel} isFeedback={isFeedback}/>
    else //default vertical
		return <VerticalCards cards={message.cards} onClick={onClick} contextPath={contextPath}/>
}

const VerticalCards = ({cards, onClick, contextPath}) => {
    return (
        <>
            { cards.map((card, cindex) => ( 
                <Card key={`card-${cindex}`} card={card} onClick={onClick} contextPath={contextPath}/>
            ))}
        </>
    );
};

const HorizontalCards = ({cards, onClick, contextPath, dynamic, user, channel, isFeedback}) => {
   const classes = useStyles();
   const state = useStoreState();
   const dispatch = useStoreDispatch();
   const [Backdrop, setOpen] = useBackdrop();

   const [cardsData, setCardsData] = useState(cards || []);
   
   const iconv = require("iconv-lite");
   
   const handleOnChange = function (index) {
       let dynamicAnalyze = state ? state.chat.dynamic : null;
       if(dynamicAnalyze)
            dynamicAnalyze.index = index;
        console.log(cardsData[index]);
	   if(cardsData[index].elements == null && dynamic.rows) {
			   
            let params = {text : dynamic.question, id : dynamic.rows[index].intent_id};
            if(dynamic.rows[index].sequence) {
                params['sequence'] = dynamic.rows[index].sequence;
            }
            
            setOpen(true);

            alteration(`${contextPath}/chat/answer`, 'POST', {
                body: JSON.stringify(Object.assign({channel: channel || 'dev', user: state ? state.user : user, domain : dynamic.domain}, params))
            }).then((res) => res.json())
                .then((data) => {
                if(dynamicAnalyze) {
                    dynamicAnalyze.rows[index] = data;
                    dispatch({ type: 'C', chat: Object.assign(state.chat, { dynamic: dynamicAnalyze }) });
                }
                console.log(data);
                
                /*  if(data.answer.mrc) {
                    data.messages[0].elements.unshift({'text' : data.answer.mrc.confidence, 'type' : 'confidence'});
                }
                */             
                setCardsData(cardsData => { cardsData[index] = {'elements': data.messages[0].elements}; 
                        return [...cardsData];  });
                }).finally(()=> setOpen(false));
                
	   } else if(dynamicAnalyze)
            dispatch({ type: 'C', chat: Object.assign(state.chat, { dynamic: dynamicAnalyze }) });
   };

    return (
        <>
        <Carousel className={classes.carousel} autoPlay={false} animation='slide' timeout={300} index={0}
            navButtonsWrapperProps={{ className: classes.navButtonsWrapperProps }} 
            navButtonsProps={{     
				style : {
					backgroundColor: '#ffff',			
					border: 'none',
					padding: 0
				}
			}} 
			fullHeightHover={false}
        	navButtonsAlwaysVisible={true}
            indicatorContainerProps={{ className: classes.indicatorContainerProps }}
            indicatorIconButtonProps={{ className: classes.indicatorIcon }}
            activeIndicatorIconButtonProps={{ className: classes.activeIndicatorIcon }}
        	NextIcon={<ControlIcon direction='r' />}
    		PrevIcon={<ControlIcon direction='l' />}
        	onChange={ (currentItem, pageIndex) => {
        		 handleOnChange(currentItem);
        	  } 
        	}
        >
            { cardsData.map((card, cindex) => ( 
                <Card key={`card-${cindex}`} card={card} onClick={onClick} contextPath={contextPath} dynamic={dynamic} index={cindex} channel={channel} isFeedback={isFeedback}/>
            ))}
        </Carousel>
        <Backdrop />
        </>
    );
};

const Feedback = ({contextPath, dynamic, index, channel}) => {  

    const [Backdrop, setOpen] = useBackdrop();
    let targetrow = dynamic.rows[index];
    const [feedbackData, setFeedbackData] =  useState(targetrow.feedback || {} ); 

    const handleClick = async(key) => {
        
        let params = Object.assign({}, feedbackData);
        params.domain_id = dynamic.domain;
        params.doc_id = targetrow.doc_id;
        params.intent_id = targetrow.intent_id;
        params.question =  params.question || dynamic.question;

        if(key == "positive" || key == "negative") {
            if(channel !== "dev") return;

            params[key] = (params[key] == 1)? 0 : 1;
            (key == "positive") ? params.negative = 0 : params.positive = 0;
        } else {
            if(channel === "dev") return;
            params[key] = parseInt(params[key] || 0) + 1;
        }

        console.log(params);
        setOpen(true);

        alteration(`${contextPath}/api/domains/${dynamic.domain}/feedback`, 'POST', {
            body: JSON.stringify(params)
        }).then((res) => res.json())
            .then((data) => {  
                console.log("data : " + data);
                params.id = data;
                setFeedbackData(params);                
                dynamic.rows[index].feedback = params;
            
        }).finally(()=> setOpen(false));
    };
  
    const classes = useStyles();
    let _positive = feedbackData.positive || 0;
    let _negative = feedbackData.negative || 0;
    
    return <>
        <div className="feedback">             
            <span>
                {  (_positive == 1)  ? 
                    <ThumbUp className={classes.thumb} style={ (channel !== "dev") ? {cursor : 'default'} : {}} onClick={() => handleClick('positive') } /> :
                    <ThumbUpOutlined className={classes.thumb} style={ (channel !== "dev") ? {cursor : 'default'} : {}}  onClick={() => handleClick('positive')} />  }
                { (_negative == 1)  ?   
                <ThumbDown className={classes.thumb} style={ (channel !== "dev") ? {cursor : 'default'} : {}} onClick={() => handleClick('negative')}/> :
                <ThumbDownOutlined className={classes.thumb} style={ (channel !== "dev") ? {cursor : 'default'} : {}} onClick={() => handleClick('negative')}/> }
            </span>          
           
            <span style={{float : 'right'}}>
                <ThumbUpOutlined className={classes.thumb} style={ (channel === "dev") ? {cursor : 'default'} : {}} onClick={() => handleClick('user_positive')} /><span>{feedbackData.user_positive || 0}</span>
                <ThumbDownOutlined className={classes.thumb} style={ (channel === "dev") ? {cursor : 'default'} : {}} onClick={() => handleClick('user_negative')}/><span>{feedbackData.user_negative || 0}</span>
            </span>
           
        </div>
        <Backdrop />
        </>
    ;
};

const Card = ({card, onClick, contextPath, dynamic, index, channel, isFeedback}) => {
    const classes = useStyles();

    return (
        <div className={classes.card}  >
            <ChatAnswer elements={card.elements} onClick={onClick} contextPath={contextPath} dynamic={dynamic} />
            { (isFeedback && dynamic !== undefined && dynamic.rows !== undefined)? <Feedback contextPath={contextPath} dynamic={dynamic} index={index} channel={channel} /> : null }
        </div>
    );
};

export default Cards;


