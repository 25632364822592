import react, { useState, useLayoutEffect } from 'react';

export function useWindowHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
        function updateHeight() {
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', updateHeight);
        updateHeight();
        return () => window.removeEventListener('resize', updateHeight);
    }, []);
    return height;
}

export function transformMessages(data) {
    let result = [];
    let dynamic = { question: data.question };
    data.messages.map((message, idx) => {
        if(idx == 0 && !data.fallback && data.answer.detector && data.answer.detector === "VECTOR" && data.rows) {
                
                delete message['template'];
                
                console.log(message.elements);
              /*  if(data.answer.mrc)  {
                    message.elements.unshift({'text' : data.answer.mrc.confidence , 'type' : 'confidence'});
                }
            */
                let cards = [ message ];
                
                for (let i = 1; i < data.answer.count; i++) {
                    cards.push({});
                }
                
                let temp = {'type': 'cards', 'align': 'horizontal', 'cards':  cards };
                message = {};
                message.elements = [temp];
                
                dynamic.rows = data.rows;
                dynamic.domain = data.answer.domain;
                result.push({ elements: message.elements, dynamic });
        } else
            result.push({ elements: message.elements, dynamic });
    })
    return result;
}