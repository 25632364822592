import React from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import Image from './tmpl/image';
import Text from './tmpl/text';
import Title from './tmpl/title';
import Subtitle from './tmpl/subtitle';
import Buttons from './tmpl/buttons';
import Iframe from './tmpl/iframe';
import Video from './tmpl/video';
import Audio from './tmpl/audio';
import Export from './tmpl/export';
import Cards from './tmpl/cards';
import Confidence from './tmpl/confidence';

import { useStoreState } from '../common/store';

function ChatAnswer({elements, onClick, loading, contextPath, dynamic, user, channel, isFeedback, sendForm}) {
    const state = useStoreState();
    if(state !== undefined)
        contextPath = state.contextPath;
    //TODO: attr 
    const getAttrs = (element) => {
        let attrs = "";
        element.map((value, key) => {
            attrs += ` ${key}='${value}'`;
        });
        return attrs;
    };

    const __chat_load_css = file => {
        let ele = document.getElementById(file);
        if(ele)
            return;
        let head = document.getElementsByTagName('head')[0];
        let link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = file.includes('http') ? file : `${contextPath}/api/files/${file}`;
        link.id = file;
        link.setAttribute('data-type', 'custom-css');
        head.appendChild(link);
    }

    if(loading)
        return <BeatLoader size={8}/>;

    return (
    elements && typeof elements !== 'string' ?
        elements.map((element, index) => {
            if(element.type === 'javascript') {
                eval(element.code);
                return null;
            }
            return (
                <div className={`box ${element.type} ${(dynamic !== undefined && dynamic.rows !== undefined && element.type=='text') ? 'textdoc' : ''}`} key={`${element.type}-${index}`}>
                {   
                    (element.type == "title") ?
                        <Title message={element} index={index} contextPath={contextPath} /> :
                    (element.type == "subtitle") ?
                        <Subtitle message={element} index={index} contextPath={contextPath} /> :
                    (element.type == "buttons") ?
                        <Buttons message={element} index={index} onClick={onClick} contextPath={contextPath} sendForm={sendForm}/> :
                    (element.type == "image") ?
                        <Image  message={element} index={index} contextPath={contextPath} /> :
                    (element.type == "text") ?
                        <Text message={element} index={index} contextPath={contextPath} /> :
                    (element.type == "iframe") ?
                        <Iframe message={element} index={index} contextPath={contextPath} /> :
                    (element.type == "video") ?
                        <Video message={element} index={index} contextPath={contextPath} /> :
                    (element.type == "audio") ?
                        <Audio message={element} index={index} contextPath={contextPath} /> :
                    (element.type == "export") ?
                        <Export message={element} index={index} contextPath={contextPath} /> :
                    (element.type == "cards") ?
                        <Cards message={element} index={index} onClick={onClick} contextPath={contextPath} dynamic={dynamic} user={user} channel={channel} isFeedback={isFeedback}/> :
                    (element.type == "confidence") ?
                        <Confidence message={element} index={index} contextPath={contextPath} /> : 
                    null
                }
                </div>  
        )})
        : 
        <div className='box text' >{elements || ''}</div>
    );
}

export default ChatAnswer;