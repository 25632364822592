import React, { useRef } from 'react';

function Buttons({index, message, onClick, sendForm}) {
    const ref = useRef();
    
    const handelClick = (text, value) => {
        if(!onClick)
            return;
        value = value || text;
        if(value.length > 0) {
            //Web URL
            if(value.indexOf("http") > -1 || value.indexOf("https") > -1) {
                window.open(value, "_blank");
            } else if(value.startsWith("mailto:") || value.startsWith("tel:") || value.startsWith("sms:")) {
                window.open(value, "_parent");    
            } else {
                let matches = value.matchAll(/^form\.(.+)\.submit\(\)$/g);
                matches = [...matches];
                if(matches.length === 0) {
                    onClick("buttons", value, text);
                    return;
                }
                let name = matches[0][1];
                let form = ref.current.parentNode.parentNode.querySelector('form');
                if(!form) {
                    onClick("buttons", value, text);
                    return;
                }
                let formData = new FormData(form);
                let param = {};
                for(let [key, value] of formData) {
                    if(param[key] === undefined)
                        param[key] = value;
                    else
                        param[key] += ',' + value;
                }
                sendForm({ text, form: { [name]: param } });
            }
        }
    };

	return (
        <div className={`btn-gline-group ${message.align || 'vertical'}`} ref={ref}>
            {message.buttons.map((button, bindex) => ( 
                <button type="button" className="btn btn-brown g-line ellipsis" key={`button-${bindex}`} title={button.text}
                    onClick={() => handelClick(button.text, button.value)}>{button.text}</button>
            ))}
        </div>
    );
}

export default Buttons;