import React, { useState } from 'react';
import { makeStyles, Backdrop as MaterialBackdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		backgroundColor: 'rgb(255 255 255 / 5%)',
		zIndex:2000
	}
});

export default function useBackdrop() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    return [() => <MaterialBackdrop className={classes.root} open={open}><CircularProgress/></MaterialBackdrop>, setOpen];
}