import React from 'react';

function Video({index, message, contextPath}) {
	const src = message.src ? (message.src.indexOf("http") > -1 || message.src.indexOf("https") > -1 ? message.src
		: `${contextPath}/api/files/${message.src}`) : '';

	return (
		<video className='media' alt={message.alt} title={message.title} controls name="media" src={src} />
	);
}

export default Video;