import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	media: {
		maxWidth: '100%',
   		margin: 'auto',
    	display: 'flex'
	}
}));

function Iframe({index, message}) {
	const classes = useStyles();
	return <iframe className={classes.media} alt={message.alt} src={message.src} title={message.title} />
}

export default Iframe;