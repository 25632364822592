import React, { useEffect, createContext, useReducer, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import useAlert from './alert';
import useBackdrop from './backdrop';
import { goErrorPage } from './func';

const stateContext = createContext();
const dispatchContext = createContext();

const { Provider:StateProvider, Consumer: StateConsumer } = stateContext;
const { Provider:DispatchProvider, Consumer: DispatchConsumer } = dispatchContext;

const getDefaultDomainInfo = (defaultDomain, domains) => {
    let domain = domains[0];
    if(defaultDomain)
        domain = domains.find(domain => domain.domain === defaultDomain) || domain;
    return { id: domain.domain, type: domain['domain.type'] };
}

const parsePermissions = (permissions) => {
    permissions = JSON.parse(permissions);
    let isAdmin = (Object.keys(permissions).includes('*') && permissions['*'].includes('*'));
    return { permissions, isAdmin };
}

export const StoreProvider = ({ children, storeData }) => {
    const [Alert, showAlert, showConfirm, showError, showInfo, setAlertTitle] = useAlert();
    const [Backdrop, showBackdrop] = useBackdrop();
    const [cookies, setCookie] = useCookies(['mode', 'domain']);
    
    let { permissions, isAdmin }= parsePermissions(storeData.permissions);

    let domains = storeData.domains;
    let domain = getDefaultDomainInfo(cookies.domain, domains);
    const initialState = {
        contextPath: storeData.contextPath,
        user: storeData.userId,
        domain,
        domains,
        chat: false,
        mode: storeData.mode != "" && cookies.mode && cookies.mode != "" ? cookies.mode : storeData.mode,
        showAlert, showConfirm, showError, showInfo, setAlertTitle,
        showBackdrop,
        permissions,
        isAdmin,
        tenant : storeData.tenant
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if(state.domain.id)
            setCookie('domain', state.domain.id, { path: '/' });
    }, [state.domain && state.domain.id]);

    return (
        <CookiesProvider>
            <StateProvider value={state}>
                <DispatchProvider value={dispatch}>{children}</DispatchProvider>
                <Alert/>
                <Backdrop/>
            </StateProvider>
        </CookiesProvider>
    );
};

function reducer(state, action) {
    switch (action.type) {
        case 'D' :
            if (action.domain.id) {
                return {
                    ...state, domain: action.domain
                };
            }
            else {
                return {
                    ...state
                };
            }
        case 'U' :
            return {
                ...state, user:action.user
            };
        case 'DL' :
            return {
                ...state, domains:action.domains
            };
        case 'C' :
            return {
                ...state, chat:action.chat
            };
        case 'M' :
            return {
                ...state, mode:action.mode
            };
        default :
            throw new Error('Unhandled action');
    }
}

export const useStoreState = () => useContext(stateContext);
export const useStoreDispatch = () => useContext(dispatchContext);

export class Mode {
    static MODE_TYPE = Object.freeze( {
        SERVICE: { name: '서비스', value: 'service' },
        DESIGN: { name: '디자인', value: 'design' }
    });

    static enableEdit() {
        // publish mode is disabled or design mode
        return useStoreState().mode !== this.MODE_TYPE.SERVICE.value;
    }

    static enablePublish() {
        // publish mode is enabled
        return useStoreState().mode !== '';
    }
}