import React from 'react';

function Image({index, message, contextPath}) {
	const src = message.src ? (message.src.indexOf("http") > -1 || message.src.indexOf("https") > -1 ? message.src
		: `${contextPath}/api/files/${message.src}`) : '';

	return <img className='media' alt={message.alt} src={src} title={message.title} />;

}

export default Image;