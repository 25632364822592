import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import Popper from '@material-ui/core/Popper';

const Autocomplete = (props) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(props.options);
    const [value, setValue] = useState(props.defaultValue || '');
    const [loading, setLoading] = useState(true);
    let lastUpdate = new Date().getTime();
    const inputRef = useRef();

    useEffect(() => setOptions(props.options), [props.options]);

    const handleInputChange = (e, value, reason) => {
        props.handleInputChange(value, options, setOptions, reason, setValue);
        loading && setLoading(false);
    }

    const handleClose = (e) => {
        let node = document.getElementsByClassName('MuiAutocomplete-root');
        if(open == false) {
            if(!(e.target.tagName === 'BODY'
                || e.target.parentNode.classList.contains('MuiPopover-root')
                || e.target.classList.contains('MuiListItem-root')
                || e.target.classList.contains('MuiAutocomplete-option')
                || (node && node[0] && node[0].contains(e.target)))) {
                    if(document.getElementsByClassName('MuiAutocomplete-listbox').length > 0) {
                        setOpen(true);
                        setTimeout(() => setOpen(false), 1);
                    }
            }
        }
    }

    useEffect(() => {
        let body = document.body;
        body.addEventListener('click', handleClose);
        return () => body.removeEventListener('click', handleClose);
    }, []);

    useEffect(() => {
        lastUpdate = new Date().getTime();
    }, [options]);

    let popperProps = props.popperProps || {};
    let className = ` ${props.className || ''}`;
    return (
        <MuiAutocomplete
            autoComplete
            includeInputInList
            value={value}
            onChange={(_, value) => setValue(value)}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            freeSolo
            options={options || []}
            filterOptions={props.showAllOptions ? data => data : undefined}
            getOptionLabel={option => option.replaceAll(/<b>/g, '').replaceAll(/<\/b>/g, '')}
            renderOption={option => <span dangerouslySetInnerHTML={{ __html: option }}/>}
            className={className}
            PopperComponent={(props) => <Popper {...props} className={props.className + className} {...popperProps}/>}
            onInputChange={handleInputChange}
            renderInput={(params) => {
                let value = params.inputProps.value;
                if(props.useRef) {
                    let ref = params.inputProps.ref;
                    value = ref.current ? ref.current.value.replaceAll('\n', '') : '';
                    if(ref.current)
                        inputRef.current = ref.current;
                }
                const Input = props.renderInput;
                return (
                    <div ref={params.InputProps.ref}>
                        <Input {...params.inputProps} value={value}/>
                    </div>
                );
            }}
            ListboxComponent={React.forwardRef((props, ref) => {
                let obj = {};
                for(let key of Object.keys(props))
                    if(key !== 'children')
                        obj[key] = props[key];
                let event = props.children.length > 0 ? props.children[0].props.onMouseOver : () => {};
                let onClickEvent = props.children.length > 0 ? props.children[0].props.onClick : () => {};
                return (
                    <ul {...obj} ref={ref}>
                        {React.Children.map(props.children, child => (
                            React.cloneElement(child, {
                                onMouseOver: e => new Date().getTime() > lastUpdate + 100 && event(e),
                                onClick: e => {
                                    let value = child.props.children.props.dangerouslySetInnerHTML.__html;
                                    if(inputRef.current)
                                        inputRef.current.value = value.replaceAll(/<b>/g, '').replaceAll(/<\/b>/g, '');
                                    onClickEvent(e);
                                }
                            })
                        ))}
                    </ul>
                )
            })}
        />
    );
};

export default Autocomplete;