import React from 'react';

function Export({index, message, contextPath}) {
	const src = message.src ? (message.src.indexOf("http") > -1 || message.src.indexOf("https") > -1 ? message.src
		: `${contextPath}/api/files/${message.src}`) : '';

	return (
		<>
			<a href={src} title={message.orgfilename} target="_blank" download className="download">{message.orgfilename}</a>
		</>
	);
}

export default Export;